import ready from 'Utils/documentReady.js';
import $ from "jquery";

ready(function(){
  $('.documents__list').each(function(i,elem) {
      if ($(this).children('li').length > 5) {
        $(this).children('li:gt(5)').hide();
        $(this).after("<div class='a-btn btn-callback'>Показать ещё</div>");
      } 
  });


  $(".documents .btn-callback" ).click(function() {
      $(this).prev('.documents__list').children('li:gt(5)').show();
      $(this).hide();
  });
});
