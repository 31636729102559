import ready from 'Utils/documentReady.js';
import $ from "jquery";

ready(function(){
  $( ".header-menu__link--service" ).click(function() {
    toggleNavigationServiceDesk($(this));
  });
  $( ".service-menu__item" ).hover(function() {
    toggleServices($(this));
  });
});

function toggleNavigationServiceDesk(element) {
  if(window.matchMedia('(min-width: 956px)').matches) {
    $(".service-menu").slideToggle(300);
  }
}
function toggleServices(element) {
  if(window.matchMedia('(min-width: 956px)').matches) {
    let sub = element.data('submenu');
    $('.service-submenu').each(function(i,elem) {
        if ($(this).data('submenu') == sub) {
            $('.service-submenu').each(function(i,elem) {
                if ($(this).css('display') == 'block') {
                    $(this).css('display', 'none');
                }
            });
            $(this).show();
        }
    });
  }
}