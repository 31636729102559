import ready from 'Utils/documentReady.js';
import $ from "jquery";
require("slick-carousel");

ready(function(){
  var slickOpts = {
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      easing: 'swing', 
      speed: 700,
      dots: false,
      autoplay: true,
      arrows: true,
      autoplaySpeed: 4000,
      variableWidth: true,
      prevArrow: $('.service-slider__prev'),
      nextArrow: $('.service-slider__next'),
    };
  $('#service-slider').slick(slickOpts);
});