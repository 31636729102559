import ready from 'Utils/documentReady.js';
import $ from "jquery";
require("slick-carousel");

ready(function(){
  var slickOpts = {
      mobileFirst: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      easing: 'swing', 
      speed: 700,
      dots: false,
      autoplay: true,
      autoplaySpeed: 4000,
      prevArrow: $('.slider-photo__prev'),
      nextArrow: $('.slider-photo__next'),
      responsive: [
      {
        breakpoint: 655,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 955,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          variableWidth: true,
          infinite: false,
        }
      },
    ]
    };
  $('#slider-photo').slick(slickOpts);
});