import ready from 'Utils/documentReady.js';
import $ from "jquery";
require("slick-carousel");

ready(function(){
  var slickOpts = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      easing: 'swing', 
      speed: 700,
      dots: true,
      autoplay: true,
      arrows: true,
      autoplaySpeed: 4000,
      prevArrow: $('.reviews-slider__prev'),
      nextArrow: $('.reviews-slider__next'),
      responsive: [
        {
          breakpoint: 954,
          settings: {
            arrows: false,
          }
        },
      ],
      customPaging: function(slick,index) {
        return '<a>' + (index + 1) + '</a>';
      }
    };
  $('#review-slider').slick(slickOpts);
});