import ready from 'Utils/documentReady.js';
import $ from "jquery";
require("slick-carousel");

ready(function(){
  var slickOpts = {
      mobileFirst: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      easing: 'swing', 
      speed: 700,
      dots: true,
      autoplay: true,
      autoplaySpeed: 4000,
      arrows: false,
    };
  $('#promo-slider').slick(slickOpts);
});