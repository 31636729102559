
/*!*
 * ВНИМАНИЕ! Этот файл генерируется автоматически.
 * Любые изменения этого файла будут потеряны при следующей компиляции.
 * Любое изменение проекта без возможности компиляции ДОЛЬШЕ И ДОРОЖЕ в 2-5 раз.
 */

/* global require */

require('../blocks/sprite-svg/sprite-svg.js');
require('../blocks/contact/contact.js');
require('../blocks/doctors-slider/doctors-slider.js');
require('../blocks/documents/documents.js');
require('../blocks/header/header.js');
require('../blocks/m-header/m-header.js');
require('../blocks/modal/modal.js');
require('../blocks/order/order.js');
require('../blocks/page-footer/page-footer.js');
require('../blocks/prices/prices.js');
require('../blocks/promo-slider/promo-slider.js');
require('../blocks/promotions/promotions.js');
require('../blocks/promotions-slider/promotions-slider.js');
require('../blocks/reviews-slider/reviews-slider.js');
require('../blocks/services/services.js');
require('../blocks/site-panel/site-panel.js');
require('../blocks/slider-photo/slider-photo.js');
require('../blocks/start/start.js');
require('./script.js');

/*!*
 * ВНИМАНИЕ! Этот файл генерируется автоматически.
 * Любые изменения этого файла будут потеряны при следующей компиляции.
 * Любое изменение проекта без возможности компиляции ДОЛЬШЕ И ДОРОЖЕ в 2-5 раз.
 */

