import ready from 'Utils/documentReady.js';
import $ from "jquery";

ready(function(){
  $( ".footer-menu-title" ).click(function() {
    toggleNavigationFooter($(this));
  });
});

function toggleNavigationFooter(element) {
  if(window.matchMedia('(max-width: 956px)').matches) {
    element.siblings(".footer-menu").slideToggle(300);
    element.toggleClass("footer-menu-title--active");
  }
}