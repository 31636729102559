import ready from 'Utils/documentReady.js';
import $ from "jquery";

ready(function(){
  $( ".submenu--price .submenu__link" ).click(function() {
    toggleTablePrice($(this));
  });

  $('.prices__list').each(function(i,elem) {
      if ($(this).children('li').length > 5) {
        $(this).children('li:gt(5)').hide();
        $(this).after("<div class='a-btn btn-callback'>Показать все цены</div>");
      }
      
  });


  $(".prices .btn-callback" ).click(function() {
      $(this).prev('.prices__list').children('li:gt(5)').show();
      $(this).hide();
  });
});

function toggleTablePrice(element) {
  $('.submenu--price .submenu__link').each(function(i,elem) {
      $(this).removeClass('submenu__link--active')
      /*if ($(this).css('display') == 'block') {
          $(this).css('display', 'none');
      }*/
  });
  element.toggleClass("submenu__link--active");

  if (element.data('href') !== 'all') {
    $('.prices').each(function(i,elem) {
      if ($(this).css('display') == 'block') {
          $(this).css('display', 'none');
      }
    });

    $('.prices').each(function(i,elem) {
      if ($(this).data('href') == element.data('href')) {
          $(this).css('display', 'block');
      }
    });
  } else {
    $('.prices').each(function(i,elem) {
      $(this).css('display', 'block');
    });
  }
}