import ready from 'Utils/documentReady.js';
import $ from "jquery";

ready(function(){
  $( ".m-header-top__clinic" ).click(function() {
    toggleNavigationTopHeader($(this));
  });

  $( ".m-nav-services__title" ).click(function() {
    toggleNavigationService($(this));
  });

  $( ".m-header-burger" ).click(function() {
    toggleBurgerMobile($(this));
  });
});

function toggleNavigationTopHeader(element) {
  if(window.matchMedia('(max-width: 956px)').matches) {
    $('.m-header-top-list').slideToggle(300);
    element.toggleClass("m-header-top__clinic--active");
  }
}

function toggleNavigationService(element) {
  if(window.matchMedia('(max-width: 956px)').matches) {
    element.next(".m-nav-services__list").slideToggle(300);
    element.toggleClass("m-nav-services__list--active");
  }
}

function toggleBurgerMobile(element) {
  if(window.matchMedia('(max-width: 956px)').matches) {
    $('.m-nav').slideToggle(300);
    element.toggleClass("m-header-burger--active");
  }
}